import { NextPage } from "next";
import React from "react";
import { Layout } from "../layout";

export const NotFoundPage: NextPage = () => {
    return (
        <Layout title="404" >
            <h2>404 - Not found</h2>
        </Layout>
    )
};


export default NotFoundPage
